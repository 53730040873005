import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { Radio } from "antd";
import {
  faBook,
  faMinus,
  faTimeline,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./PT5.module.scss";
import subject from "../../../assets/images/bachkhoa.png";
import Subject from "../../Elements/Subject/Subject";
import Button from "../../Elements/Button/Button";
import Loading from "../../Elements/Loading/Loading";
import Logo from "../../../assets/images/facebook.png";
import Zalo from "../../../assets/images/icon/zalo.png";
import youtube from "../../../assets/images/icon/youtube.png";
const cx = classNames.bind(styles);
function Selection() {
  const DataDefaultPass = [
    {
      name: "Chương trình đại trà",

      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
    {
      name: "Chương trình OISP( giảng dạy bằng tiếng Anh, tiên tiến,...)",
      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
  ];
  const DataDefaultNoPass = [
    {
      name: "Chương trình đại trà",

      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
    {
      name: "Chương trình OISP( giảng dạy bằng tiếng Anh, tiên tiến,...)",
      assignments: [
        {
          name: "Chưa có dữ liệu",
          price: 0,
          path: "",
        },
      ],
    },
  ];
  const DataDepartment = [
    {
      name: "Khoa học máy tính",
      price: 79.84,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật máy tính",
      price: 78.26,
      path: "/learning/cse",
    },
    {
      name: "Nhóm ngành kỹ thuật điện, viễn thông, tự động hóa",
      price: 66.59,
      path: "/learning/ddt",
    },
    {
      name: "Kỹ thuật cơ khí",
      price: 58.49,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật cơ điện tử",
      price: 71.81,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật Dệt, công nghệ Dệt",
      price: 57.3,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật hóa học",
      price: 70.83,
      path: "/learning/kthh",
    },
    {
      name: "Kỹ thuật xây dựng",
      price: 55.4,
      path: "/learning/ktxd",
    },
    {
      name: "Kiến trúc",
      price: 59.36,
      path: "/learning/ktxd",
    },
    {
      name: "Kỹ thuật địa chất và dầu khí",
      price: 58.02,
      path: "/learning/dcvdk",
    },
    {
      name: "Quản lý công nghiệp",
      price: 65.17,
      path: "/learning/qlcn",
    },
    {
      name: "Kỹ thuật môi trường",
      price: 54.0,
      path: "/learning/mtvtn",
    },
    {
      name: "Kỹ thuật hệ thống, Logistic",
      price: 73.51,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật vật liệu",
      price: 55.36,
      path: "/learning/cnvl",
    },
    {
      name: "Vật lý kỹ thuật",
      price: 60.81,
      path: "/learning/khud",
    },
    {
      name: "Cơ kỹ thuật",
      price: 60.65,
      path: "/learning/khud",
    },
    {
      name: "Kỹ thuật nhiệt",
      price: 60.46,
      path: "/learning/ck",
    },
    {
      name: "Bảo dưỡng công nghiệp",
      price: 57.33,
      path: "/learning/bdcn",
    },
    {
      name: "Kỹ thuật Oto",
      price: 68.73,
      path: "/learning/ktgt",
    },
    {
      name: "Kỹ thuật tàu thủy, hàng không",
      price: 59.94,
      path: "/learning/ktgt",
    },
  ];
  const DataOISP = [
    {
      name: "Khoa học máy tính",
      price: 75.63,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật máy tính",
      price: 61.39,
      path: "/learning/cse",
    },
    {
      name: "Kỹ thuật điện điện tử",
      price: 61.66,
      path: "/learning/ck/ddt",
    },
    {
      name: "Kỹ thuật cơ khí",
      price: 58.49,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật cơ điện tử",
      price: 62.28,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật cơ điện tử (chuyên nghành Robot)",
      price: 62.28,
      path: "/learning/ck",
    },
    {
      name: "Kỹ thuật hóa học",
      price: 60.93,
      path: "/learning/kthh",
    },
    {
      name: "Kỹ thuật xây dựng",
      price: 55.4,
      path: "/learning/ktxd",
    },
    {
      name: "Kiến trúc",
      price: 59.36,
      path: "/learning/ktxd",
    },
    {
      name: "Công nghệ sinh học",
      price: 60.01,
      path: "/learning/kthh",
    },
    {
      name: "Công nghệ thực phẩm",
      price: 63.05,
      path: "/learning/kthh",
    },
    {
      name: "Kỹ thuật dầu khí",
      price: 58.02,
      path: "/learning/dcvdk",
    },
    {
      name: "Quản lý công nghiệp",
      price: 61.41,
      path: "/learning/qlcn",
    },
    {
      name: "Kỹ thuật môi trường",
      price: 54,
      path: "/learning/ktmt",
    },
    {
      name: "Logistic và chuỗi cung ứng",
      price: 60.78,
      path: "/learning/qlcn",
    },
    {
      name: "Kỹ thuật vật liệu",
      price: 55.36,
      path: "/learning/cnvl",
    },
    {
      name: "Vật lý kỹ thuật",
      price: 60.81,
      path: "/learning/khud",
    },
    {
      name: "Kỹ thuật Oto",
      price: 60.7,
      path: "/learning/ktgt",
    },
    {
      name: "Cơ kỹ thuật-Tăng cường tiếng Nhật",
      price: 59.77,
      path: "/learning/ck",
    },
    {
      name: "Khoa học máy tính-Tăng cường tiếng Nhật",
      price: 66.76,
      path: "/learning/cse",
    },

    {
      name: "Kỹ thuật hàng không",
      price: 59.94,
      path: "/learning/ktgt",
    },
  ];
  const [statusCourseBasic, setStatusCourseBasic] = useState(true);
  const [statusCourseAdvance, setStatusCourseAdvance] = useState(false);
  const [all9, setAll9] = useState(null);
  const [math10, setMath10] = useState(null);
  const [math11, setMath11] = useState(null);
  const [math12, setMath12] = useState(null);
  const [physic10, setPhysic10] = useState(null);
  const [physic11, setPhysic11] = useState(null);
  const [physic12, setPhysic12] = useState(null);
  const [chemistry10, setChemistry10] = useState(null);
  const [chemistry11, setChemistry11] = useState(null);
  const [chemistry12, setChemistry12] = useState(null);
  const [subject1, setSubject1] = useState(null);
  const [subject2, setSubject2] = useState(null);
  const [subject3, setSubject3] = useState(null);
  const [dgnl, setDgnl] = useState(null);
  const [speacialPoint, setSpeacialPoint] = useState(null);
  const [hocba, setHocBa] = useState(0);
  const [THPT, setTHPT] = useState(0);
  const [DGNL, setDGNL] = useState(0);
  const [PT5, setPT5] = useState(0);
  const [dataPass, setDataPass] = useState(DataDefaultPass);
  const [dataNoPass, setNoDataPass] = useState(DataDefaultPass);
  const [textInput, setTextInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [texOpacity, setTexOpacity] = useState(false);
  const [valueKV, setValueKV] = useState(1);
  const [valueUT, setValueUT] = useState(0);
  const [Bonus1, setValueBonus1] = useState(0.75);
  const [Bonus2, setValueBonus2] = useState(30);

  const onChangeKV = (e) => {
    setValueKV(e.target.value);
  };

  const onChangeUT = (e) => {
    setValueUT(e.target.value);
  };
  function handleResize() {
    setTextInput(window.innerWidth > 576 ? "Nhập điểm..." : "0");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkNumber = (value) => {
    return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp.
  };
  useEffect(() => {
    document.title = "Tính điểm xét tuyển phương thức 5 Bách Khoa 2024";
  });
  function demSoChuSoThapPhan(so) {
    // Kiểm tra nếu số là số nguyên
    if (Number.isInteger(so)) {
      return 0;
    }

    // Chuyển đổi số thành chuỗi
    const soChuoi = so.toString();

    // Tìm vị trí của dấu thập phân
    const viTriThapPhan = soChuoi.indexOf(".");

    // Nếu không tìm thấy dấu thập phân, số là số nguyên
    if (viTriThapPhan === -1) {
      return 0;
    }

    // Tính số lượng chữ số sau dấu thập phân
    return soChuoi.length - viTriThapPhan - 1;
  }
  const handleCalculate = () => {
    setIsLoading(true);
    setIsResult(false);
    setTexOpacity(true);
    setSpeacialPoint((pre) => pre * 1);
    setTimeout(() => {
      setIsLoading(false);
      setIsResult(true);
      setTexOpacity(false);
    }, 1000);
    const Score9 =
      math10 * 1 +
      math11 * 1 +
      math12 * 1 +
      physic10 * 1 +
      physic11 * 1 +
      physic12 * 1 +
      chemistry10 * 1 +
      chemistry11 * 1 +
      chemistry12 * 1;
    const trunghoc = subject1 * 1 + subject2 * 1 + subject3 * 1;
    const HOCBA = all9 * 1 === 0 || all9 * 1 === null ? Score9 : all9;
    let cong1 = 0.75;
    let cong2 = 30;
    let soThapPhan1;
    let soThapPhan2;

    let UTPT = 0;
    let UTNL = 0;
    //console.log("valueUT*1", 1);
    switch (valueUT * 1) {
      case 0:
        UTPT = 0;
        UTNL = 0;
        break;
      case 1:
        UTPT = 2;
        UTNL = 80;
        break;
      case 2:
        UTPT = 1;
        UTNL = 40;
        break;
      default:
        UTPT = 0;
        UTNL = 0;
    }
    //console.log("after", UTPT, UTNL);
    switch (valueKV * 1) {
      case 1:
        cong1 =
          trunghoc > 22.5
            ? ((30 - trunghoc) / 7.5) * (0.75 + UTPT)
            : 0.75 + UTPT;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * (30 + UTNL) : 30 + UTNL;
        soThapPhan1 = demSoChuSoThapPhan(cong1);
        soThapPhan2 = demSoChuSoThapPhan(cong2);
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
      case 2:
        cong1 =
          trunghoc > 22.5
            ? ((30 - trunghoc) / 7.5) * (0.25 + UTPT)
            : 0.25 + UTPT;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * (10 + UTNL) : 10 + UTNL;
        soThapPhan1 = demSoChuSoThapPhan(cong1);
        soThapPhan2 = demSoChuSoThapPhan(cong2);
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
      case 3:
        cong1 =
          trunghoc > 22.5 ? ((30 - trunghoc) / 7.5) * (0 + UTPT) : 0 + UTPT;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * UTNL : UTNL;
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
      case 4:
        cong1 =
          trunghoc > 22.5
            ? ((30 - trunghoc) / 7.5) * (0.55 + UTPT)
            : 0.5 + UTPT;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * (20 + UTNL) : 20 + UTNL;
        soThapPhan1 = demSoChuSoThapPhan(cong1);
        soThapPhan2 = demSoChuSoThapPhan(cong2);
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
      case 5:
        cong1 = trunghoc > 22.5 ? ((30 - trunghoc) / 7.5) * 2 : 2;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * 80 : 80;
        soThapPhan1 = demSoChuSoThapPhan(cong1);
        soThapPhan2 = demSoChuSoThapPhan(cong2);
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
      case 6:
        cong1 = trunghoc > 22.5 ? ((30 - trunghoc) / 7.5) * 1 : 1;
        cong2 = dgnl > 900 ? ((1200 - dgnl) / 300) * 40 : 40;
        soThapPhan1 = demSoChuSoThapPhan(cong1);
        soThapPhan2 = demSoChuSoThapPhan(cong2);
        setValueBonus1(soThapPhan1 < 2 ? cong1 : cong1.toFixed(2));
        setValueBonus2(soThapPhan2 < 2 ? cong2 : cong2.toFixed(2));
        break;
    }
    setHocBa((HOCBA * 1).toFixed(1));
    setDGNL((dgnl * 1 + cong2).toFixed(2));
    setTHPT((trunghoc + cong1).toFixed(2));
    let result = 0;
    const rateTHPT = 0.2;
    const rateHocBa = 0.1;
    const rateDGNL = 0.7;
    if (dgnl * 1 === 0 || dgnl * 1 === null) {
      if (trunghoc === 0 || trunghoc === null) {
      } else {
        //không thi đgnl
        result =
          (trunghoc + cong1) * 3 * rateTHPT +
          ((((rateDGNL * 0.75 * (trunghoc + cong1)) / 30) * 1200) / 990) * 90 +
          rateHocBa * HOCBA +
          speacialPoint * 1;
        setDGNL(0);
      }
    } else {
      if (trunghoc === 0 || trunghoc === null) {
        //không thi thpt
        result =
          ((dgnl * 1 + cong2) / 990) * 90 * (rateTHPT + rateDGNL) +
          rateHocBa * HOCBA +
          speacialPoint * 1;
      } else {
        //thi đủ
        result =
          ((dgnl * 1 + cong2) / 990) * 90 * rateDGNL +
          (trunghoc + cong1) * 3 * rateTHPT +
          rateHocBa * HOCBA +
          speacialPoint * 1;
        //console.log("DGNL", (dgnl / 990) * 90 * rateDGNL);
      }
    }
    UpdateResult(result);

    setPT5(result.toFixed(2));
  };
  const UpdateResult = (result) => {
    DataDefaultPass[0].assignments = handleFilterPassNormal(result);
    DataDefaultPass[1].assignments = handleFilterPassOISP(result);
    DataDefaultNoPass[0].assignments = handleFilterNoPassNormal(result);
    DataDefaultNoPass[1].assignments = handleFilterNoPassOISP(result);
    setDataPass(DataDefaultPass);
    setNoDataPass(DataDefaultNoPass);
  };
  const handleFilterPassOISP = (score) => {
    const data = DataOISP.filter((course) => {
      return course.price < score || course.price === score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterNoPassOISP = (score) => {
    const data = DataOISP.filter((course) => {
      return course.price > score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterPassNormal = (score) => {
    const data = DataDepartment.filter((course) => {
      return course.price < score || course.price === score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  const handleFilterNoPassNormal = (score) => {
    const data = DataDepartment.filter((course) => {
      return course.price > score;
    });
    data.sort((a, b) => b.price - a.price);
    return data;
  };
  return (
    <div className={cx("container")}>
      <div>
        <div className={cx("intro")}>
          <h1>Tính Điểm Phương Thức 5 Xét Tuyển Đại Học ĐHBK HCM 2024</h1>
          <span>
            Trang web Bách Khoa được phát triển bởi{" "}
            <a
              target="_blank"
              className={cx("press")}
              href="https://www.facebook.com/profile.php?id=100086390823801"
            >
              Nguyễn Quốc Vương K19
            </a>
            , hiện tại mình đang học Thạc Sĩ tại trường Bách Khoa HCM, đồng thời
            có mở lớp học thêm các môn đại cương cho tân sinh viên K24{" "}
            <a target="_blank" href="https://bachkhoacncp.com/home/550">
              Xem lớp
            </a>{" "}
            với kinh nghiệm dạy 5 năm, hơn 2000 sinh viên Bách Khoa mỗi năm.{" "}
            <a
              target="_blank"
              href="https://www.facebook.com/share/p/5rY1xffDBzHgVDHH/"
            >
              Thành tích K23
            </a>
          </span>
          <div>
            <div className={cx("advertisement-mobile")}>
              <div className={cx("container-advertisement")}>
                <div className={cx("container-image")}>
                  <img className={cx("image")} src={subject}></img>
                  <h5>Nhóm chia sẻ tài liệu ở đại học</h5>
                  <Button
                    link={"https://www.facebook.com/groups/hcmutcncp"}
                    text={"Xem nhóm"}
                  ></Button>
                </div>

                <div className={cx("detail-advertisement")}>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faUsers}
                    ></FontAwesomeIcon>
                    <span>Sinh viên Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faFacebook}
                    ></FontAwesomeIcon>
                    <span>Nhóm học tập lớn nhất Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faBook}
                    ></FontAwesomeIcon>
                    <span>Chia sẻ tài liệu free</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faTimeline}
                    ></FontAwesomeIcon>
                    <span>Anh chị nhiệt tình</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faNewspaper}
                    ></FontAwesomeIcon>
                    <span>Tài liệu mới&chất lượng</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx("document")}>
              <div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>
                      Nhập điểm học bạ ( *Dùng dấu (.) cho số thập phân* )
                    </span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input", { input_mobile: true })}>
                      <span>Tổng</span>
                      <input
                        placeholder="Nhập điểm tổng 9 môn"
                        type="text"
                        value={all9}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setAll9(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <span>
                    <i>hoặc</i>
                  </span>
                  <div className={cx("input-score")}>
                    <div className={cx("input")}>
                      <span>Toán 10</span>

                      <input
                        value={math10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 10</span>

                      <input
                        value={physic10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 10</span>

                      <input
                        value={chemistry10}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry10(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Toán 11</span>

                      <input
                        value={math11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 11</span>

                      <input
                        value={physic11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 11</span>

                      <input
                        value={chemistry11}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry11(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Toán 12</span>

                      <input
                        value={math12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setMath12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Lý 12</span>

                      <input
                        value={physic12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setPhysic12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Hóa, Anh 10</span>

                      <input
                        value={chemistry12}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setChemistry12(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Nhập điểm thi THPT</span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input")}>
                      <span>Môn 1</span>

                      <input
                        value={subject1}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject1(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Môn 2</span>

                      <input
                        value={subject2}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject2(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                    <div className={cx("input")}>
                      <span>Môn 3</span>

                      <input
                        value={subject3}
                        onChange={(e) => {
                          checkNumber(e.target.value) &&
                            setSubject3(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Nhập điểm thi ĐGNL(điểm chưa cộng vùng)</span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input", { input_mobile: true })}>
                      <span>ĐGNL</span>
                      <input
                        value={dgnl}
                        onChange={(e) => {
                          setDgnl(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Chọn khu vực sinh sống</span>
                  </div>

                  <div className={cx("input-score")}>
                    <Radio.Group onChange={onChangeKV} value={valueKV}>
                      <Radio value={1}>KV1</Radio>
                      <Radio value={2}>KV2</Radio>
                      <Radio value={3}>KV3</Radio>
                      <Radio value={4}>KV2-NT</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>Chọn đối tượng ưu tiên</span>
                  </div>

                  <div className={cx("input-score")}>
                    <Radio.Group onChange={onChangeUT} value={valueUT}>
                      <Radio value={1}>Đối tượng ưu tiên 1</Radio>
                      <Radio value={2}>Đối tượng ưu tiên 2</Radio>
                      <Radio value={0}>Không có</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className={cx("container-input-score")}>
                  <div className={cx("title-score")}>
                    {/* <img src={bullet}></img> */}
                    <span>
                      Nhập điểm cộng thành tích cá nhân và văn thể mỹ (mức 0-10
                      điểm)
                    </span>
                  </div>

                  <div className={cx("input-score")}>
                    <div className={cx("input", { input_mobile: true })}>
                      <span>Điểm cộng</span>
                      <input
                        value={speacialPoint}
                        onChange={(e) => {
                          setSpeacialPoint(e.target.value);
                        }}
                        placeholder={textInput}
                        type="text"
                      ></input>
                    </div>
                  </div>
                  <i>
                    Lưu ý, điểm cộng thành tích các nhân, văn thể mỹ không có
                    quy định cụ thể. Bộ phận xét tuyển sẽ cân nhắc từng hồ sơ mà
                    cộng thêm điểm.
                  </i>
                </div>
              </div>
              <div className={cx("Group")}>
                <div className={cx("sub-group")}>
                  <a
                    href="https://youtu.be/Sg905e5sL_E"
                    target="_blank"
                    className={cx("logo-container")}
                  >
                    <img className={cx("logo")} src={youtube}></img>
                    <h2 className={cx("header-title")}>
                      Hướng dẫn cách tính năm 2024
                    </h2>
                  </a>
                </div>
                <div className={cx("sub-group")}>
                  <a
                    href="https://www.facebook.com/groups/hcmutcncp"
                    target="_blank"
                    className={cx("logo-container")}
                  >
                    <img className={cx("logo")} src={Logo}></img>
                    <h2 className={cx("header-title")}>
                      Nhóm facebook hỏi đáp tuyển sinh
                    </h2>
                  </a>
                </div>
                <div className={cx("sub-group")}>
                  <a
                    href="https://zalo.me/g/mwduif387"
                    target="_blank"
                    className={cx("logo-container")}
                  >
                    <img className={cx("logo")} src={Zalo}></img>
                    <h2 className={cx("header-title")}>
                      Nhóm Zalo hỏi đáp tuyển sinh 2024
                    </h2>
                  </a>
                </div>
              </div>
              <div className={cx("bt-cal")}>
                <Button
                  onclick={handleCalculate}
                  cal
                  opacityText={texOpacity}
                  text={"Tính toán"}
                >
                  {isLoading && <Loading></Loading>}
                </Button>
              </div>
            </div>
            <div className={cx("document")}>
              {isResult && (
                <>
                  <div>
                    <span>
                      Trang web Bách Khoa được phát triển bởi{" "}
                      <a
                        className={cx("press")}
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=100086390823801"
                      >
                        Nguyễn Quốc Vương K19
                      </a>
                      , hiện tại mình đang học Thạc Sĩ tại trường Bách Khoa HCM,
                      đồng thời có mở lớp học thêm các môn đại cương cho tân
                      sinh viên K24{" "}
                      <a
                        target="_blank"
                        href="https://bachkhoacncp.com/home/300"
                      >
                        Xem lớp
                      </a>{" "}
                      với kinh nghiệm dạy 5 năm, hơn 2000 sinh viên Bách Khoa
                      mỗi năm.{" "}
                      <a
                        target="_blank"
                        href="https://www.facebook.com/share/p/5rY1xffDBzHgVDHH/"
                      >
                        Thành tích K23
                      </a>
                    </span>
                  </div>
                  <div>
                    <h2>Kết quả</h2>
                    <div className={cx("title-result")}>
                      <span>Điểm học bạ: {hocba}</span>
                      <span>
                        Điểm ĐGNL: {DGNL}{" "}
                        {dgnl * 1 == 0 || (
                          <span>
                            (đã <span className={cx("bonus")}>+{Bonus2} </span>
                            điểm vùng)
                          </span>
                        )}
                      </span>

                      <span>
                        Điểm THPTQG: {THPT} (đã{" "}
                        <span className={cx("bonus")}>+{Bonus1} </span> điểm
                        vùng)
                      </span>

                      <span>Điểm cộng thành tích: {speacialPoint}</span>
                    </div>
                    <div className={cx("score")}>
                      <span>Điểm tổng xét tuyển PT5 năm 2024: {PT5}</span>

                      {THPT * 1 === 0 && (
                        <>
                          <br></br>
                          <span className={cx("note")}>
                            Lưu ý, vì bạn không thi THPT nên khả năng cao sẽ
                            rớt, nhà trường xét các bạn đã thi cả 2 kì thi ĐGNL
                            và THPT trước còn slot mới xét tiếp
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={cx("program")}>
                      <span>
                        Với mức điểm <span>{PT5}</span> bạn sẽ nhận được kết quả
                        như sau: ( so với điểm chuẩn 2023,{" "}
                        <a
                          href="https://drive.google.com/drive/u/1/folders/1GEGs7Q3Nehv32KEEBsC2WzHhToEVjQNX"
                          target="_blank"
                          className={cx("score-standard")}
                        >
                          Xem điểm các năm
                        </a>{" "}
                        )
                      </span>
                      <a
                        target="_blank"
                        className={cx("hightline")}
                        href={
                          "/blogs/cachtinhdiemphuongthuc5-phuongthucxettuyennhieutieuchidaihocbachkhoahcm"
                        }
                      >
                        Cách tính điểm 2024
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href="https://hcmut.edu.vn/bai-viet/chuong-trinh-dao-tao-tu-khoa-2019"
                      >
                        Xem chương trình học từng ngành
                      </a>
                    </div>
                    <div
                      onClick={() => {
                        setStatusCourseBasic((state) => {
                          return !state;
                        });
                      }}
                      className={cx("basic-course")}
                    >
                      <div>
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faMinus}
                        ></FontAwesomeIcon>
                        <span>1. Các ngành bạn đủ điểm</span>
                      </div>
                      <span>
                        {dataPass[0].assignments.length +
                          dataPass[1].assignments.length}
                        /{DataDepartment.length + DataOISP.length} ngành
                      </span>
                    </div>
                    {statusCourseBasic && (
                      <div>
                        <div>
                          {dataPass?.map((ass) => {
                            return (
                              <Subject
                                pt5
                                active
                                name={ass.name}
                                weight={"Điểm"}
                                data={ass.assignments}
                              ></Subject>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        setStatusCourseAdvance((state) => {
                          return !state;
                        });
                      }}
                      className={cx("basic-course")}
                    >
                      <div>
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faMinus}
                        ></FontAwesomeIcon>
                        <span>2. Các khoa/ngành bạn không đủ điểm</span>
                      </div>
                      <span>
                        {dataNoPass[0].assignments.length +
                          dataNoPass[1].assignments.length}
                        /{DataDepartment.length + DataOISP.length} ngành
                      </span>
                    </div>
                    {statusCourseAdvance && (
                      <div>
                        <div>
                          {dataNoPass?.map((ass) => {
                            return (
                              <Subject
                                active
                                pt5
                                name={ass.name}
                                weight={"Điểm"}
                                data={ass.assignments}
                              ></Subject>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={cx("content")}></div>
      </div>
      <div className={cx("advertisement-desktop")}>
        <div className={cx("link-intro")}>
          <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
          <div>
            <Button
              link={"/home/250"}
              pt5
              text={"Thông Lớp Đại Cương"}
            ></Button>
            <Button
              link={"https://zalo.me/g/mwduif387"}
              blank
              pt5
              text={"Nhóm Zalo"}
            ></Button>
            <Button link={"/home/300"} pt5 text={"Tài Liệu Các Khoa"}></Button>
            <Button
              link={"document/290"}
              pt5
              text={"Khóa học miễn phí"}
            ></Button>
          </div>
        </div>
        <div className={cx("container-advertisement")}>
          <div className={cx("container-image")}>
            <img className={cx("image")} src={subject}></img>
          </div>
          <h5>Nhóm chia sẻ tài liệu ở đại học</h5>
          <Button
            link={"https://www.facebook.com/groups/hcmutcncp"}
            text={"Xem nhóm"}
          ></Button>
          <div className={cx("detail-advertisement")}>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faUsers}
              ></FontAwesomeIcon>
              <span>Sinh viên Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faFacebook}
              ></FontAwesomeIcon>
              <span>Nhóm học tập lớn nhất Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faBook}
              ></FontAwesomeIcon>
              <span>Chia sẻ tài liệu free</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faTimeline}
              ></FontAwesomeIcon>
              <span>Anh chị nhiệt tình</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faNewspaper}
              ></FontAwesomeIcon>
              <span>Tài liệu mới&chất lượng</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selection;
