import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoute } from "./Routes/Routes.js";
import DefaultLayout from "./Components/Layout/DefaultLayout/DefaultLayout";
import classNames from "classnames/bind";
import gt1 from "./assets/images/Course/gt1.png";
import styles from "./App.module.scss";
import { useEffect, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Components/Elements/Button/Button.js";
import { useRef } from "react";
const cx = classNames.bind(styles);
function App() {
  const [isPopup, setIsPopup] = useState(false);
  const [active, setActive] = useState(false);
  const checkPopup = useRef();
  function setCookie(cname, cvalue, exmin) {
    const d = new Date();
    d.setTime(d.getTime() + exmin * 5 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  useEffect(() => {
    let allcookies = document.cookie;
    checkPopup.current = true;
    const cookiearray = allcookies.split(";");
    console.log(cookiearray);
    for (var i = 0; i < cookiearray.length; i++) {
      let name = cookiearray[i].split("=")[0];
      if (name === "firsttime") {
        checkPopup.current = false;
        break;
      }
    }
    setActive(checkPopup.current);
    setIsPopup(checkPopup.current);
  }, []);
  return (
    <div>
      <div className={cx("popupModal", { active_con: isPopup })}>
        <section className={cx("section", { active: active })}>
          <div
            onClick={() => {
              checkPopup.current && setCookie("firsttime", true, 60);
              setActive(false);
              setTimeout(() => {
                setIsPopup(false);
              }, 400);
            }}
            className={cx("overlay")}
          ></div>
          <div className={cx("modal-box")}>
            {/* <i className={cx("")}"fa-regular fa-circle-check"></i> */}
            <div className={cx("header-title")}>
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z"
                ></path>
              </svg>
              <h2>{"Thông báo sale lớn lớp giải tích 1 K24"}</h2>
              <div className={cx("container-close")}>
                <FontAwesomeIcon
                  onClick={() => {
                    checkPopup.current && setCookie("firsttime", true, 60);
                    setActive(false);
                    setTimeout(() => {
                      setIsPopup(false);
                    }, 400);
                  }}
                  className={cx("icon")}
                  icon={faClose}
                ></FontAwesomeIcon>
              </div>
            </div>

            <img className={cx("img")} src={gt1}></img>
            <h3 className={cx("h", { displaydesktop: true })}>
              Tham gia các lớp đại cương CNCP sẽ được học từ cơ bản đến A+, được
              hỗ trợ hỏi bài, giải quiz, tặng btl các môn gt1, vl1, đstt, hóa,..
              báo cáo + bài soạn TNVL, báo cáo hóa đại cương.
            </h3>
            <h3 className={cx("h", { displaymobile: true })}>
              Xem lốp đại cương
            </h3>

            <div className={cx("buttons")}>
              <Button
                height
                onclick={() => {
                  checkPopup.current && setCookie("firsttime", true, 60);
                }}
                link={
                  "https://www.facebook.com/groups/hcmutcncp/permalink/1412075199747027/?rdid=6WTqJXCxl5uXMmiD&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fp%2F5rY1xffDBzHgVDHH%2F"
                }
                text={"Xem thành tích"}
              ></Button>
            </div>
          </div>
        </section>
      </div>
      <Router>
        <Routes>
          {publicRoute.map((route, index) => {
            const Page = route.component;
            return (
              <Route
                path={route.path}
                element={
                  <DefaultLayout>
                    <Page />
                  </DefaultLayout>
                }
              ></Route>
            );
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
